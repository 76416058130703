import { findSegment, isFullLenght } from './media-composition-helpers';
import { urnToId } from '../../../../play-core/utils/urn-helper';
export const calculateProgress = (progressInSeconds, mediaComposition, urn) => {
  if (!progressInSeconds || !mediaComposition || !urn) {
    return undefined;
  }
  if (isFullLenght(mediaComposition, urn)) {
    const progressInPercentage = calculatePercentage(mediaComposition.getMainChapter().duration, progressInSeconds);
    return createProgress(urn, progressInSeconds, progressInPercentage);
  }
  const segment = findSegment(mediaComposition, urn);
  if (segment) {
    const segmentStart = segment.markIn / 1000;
    let segmentProgress = Math.max(progressInSeconds - segmentStart, 0);
    segmentProgress = Math.min(segmentProgress, segment.duration / 1000);
    const progressInPercentage = calculatePercentage(segment.duration, segmentProgress);
    return createProgress(urn, segmentProgress, progressInPercentage);
  }
};
const createProgress = (urn, progressInSeconds, progressInPercentage) => ({
  id: urnToId(urn),
  progressInSeconds,
  progressInPercentage,
  timestamp: new Date().getTime()
});
export const calculatePercentage = (durationInMilliseconds, progressInSeconds) => {
  const durationInSeconds = durationInMilliseconds / 1000;
  return Math.min(Math.round(100 / durationInSeconds * progressInSeconds), 100);
};